<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar almacén</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Is virtual-->
        <div class="mb-4">
          <label for="virtual" class="mb-2 block">¿Es virtual?</label>
          <vs-switch name="virtual" id="virtual" v-model="document.isVirtual"/>
        </div>
        <!-- Name -->
        <vs-input @blur="document.name= trimString(document.name)" label="Nombre*" v-model="document.name"
                  class="w-full" name="name"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- Department -->
        <vs-input @blur="document.department= trimString(document.department)" label="Departamento*"
                  v-model="document.department" class="mt-5 w-full" name="department"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('department')">{{ errors.first('department') }}</span>

        <!-- Province -->
        <vs-input @blur="document.province= trimString(document.province)" label="Provincia*"
                  v-model="document.province" class="mt-5 w-full" name="province"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('province')">{{ errors.first('province') }}</span>

        <!-- District -->
        <vs-input @blur="document.district= trimString(document.district)" label="Distrito*"
                  v-model="document.district" class="mt-5 w-full" name="district"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('district')">{{ errors.first('district') }}</span>

        <!-- Ubigeo -->
        <vs-input @blur="document.ubigeo= trimString(document.ubigeo)" label="Ubigeo*"
                  v-model="document.ubigeo" class="mt-5 w-full" name="ubigeo"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('ubigeo')">{{ errors.first('ubigeo') }}</span>

        <!-- Address -->
        <vs-input @blur="document.address= trimString(document.address)" label="Dirección*"
                  v-model="document.address" class="mt-5 w-full" name="address"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('address')">{{ errors.first('address') }}</span>

        <!-- Business Name -->
        <vs-input @blur="document.businessName= trimString(document.businessName)" label="Razón social*"
                  v-model="document.businessName" class="mt-5 w-full" name="businessName"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('businessName')">{{ errors.first('businessName') }}</span>

        <!-- RUC -->
        <vs-input @blur="document.ruc= trimString(document.ruc)" label="Número de RUC*"
                  v-model="document.ruc" class="mt-5 w-full" name="ruc"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('ruc')">{{ errors.first('ruc') }}</span>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

import { db, auth, FieldValue } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      document: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          ...this.data
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          // Update warehouse
          let obj = {
            ...this.document
          }
          await db.collection('warehouses').doc(this.document.id).update({
            ...obj,
            uid: auth.currentUser.uid,
            updatedAt: FieldValue.serverTimestamp()
          })
          this.$emit('update', obj)
          this.$emit('closeSidebar')
          this.progress = false
          this.$vs.notify({
            color: 'success',
            title: 'Almacén',
            text: 'Almacén actualizado correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
